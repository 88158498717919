import logo from "./assets/img/dredyba-logo-bw.svg";
import { Navbar } from "./components/Navbar/Navbar";
import { Footer } from "./components/Footer/Footer";
import { Trans, useTranslation } from "react-i18next";
import {
  Box,
  Container,
  ImageList,
  ImageListItem,
  Typography,
  useMediaQuery,
  Link,
  styled,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import headerVideoMobile from "./assets/video/dredyba-header-mobile.mp4";
import headerVideoDesktop from "./assets/video/dredyba-header-desktop.mp4";

import aboutImg from "./assets/img/studio/dredyba-studio-simpsons.webp";

import sectionImg1 from "./assets/img/studio/dredyba-studio-2.webp";
import sectionImg2 from "./assets/img/studio/dredyba-studio-8.webp";
import sectionImg3 from "./assets/img/studio/dredyba-studio-5.webp";
import sectionImg4 from "./assets/img/studio/dredyba-studio-6.webp";

import buildingImg from "./assets/img/studio/dredyba-studio-building.webp";
import { useRef } from "react";

const services = [
  {
    key: "creation",
    img: require(`./assets/img/services/dredyba-service-creation.webp`),
  },
  {
    key: "extension",
    img: require(`./assets/img/services/dredyba-service-extension.webp`),
  },
  {
    key: "repair",
    img: require(`./assets/img/services/dredyba-service-repair.webp`),
  },
  {
    key: "pseudo",
    img: require(`./assets/img/services/dredyba-service-pseudo.webp`),
  },
];

const gallery: { img: string; title: string }[] = [];
for (var i = 1; i <= 24; i++) {
  const fileName = `dredyba-gallery-${i}`;
  gallery.push({
    img: require(`./assets/img/gallery/${fileName}.webp`),
    title: fileName,
  });
}

const StyledSection = styled(Container)`
  padding-top: 85px;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

const StyledSectionImage = styled(Box)`
  height: 50vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  ${(props) => props.theme.breakpoints.down("md")} {
    background-attachment: unset;
  }
`;

const WatermarkLogo = styled("img")`
  height: 100%;
  position: fixed;
  z-index: -9999;
  opacity: 0.04;
  left: 0;
  right: 0;
  top: 80px;
  width: 100%;
  height: calc(100vh - 80px);
  margin: auto;
`;

const Header = styled(Box)`
  position: relative;
  overflow: hidden;
`;

export const App = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const videoRef = useRef<HTMLVideoElement>(null);
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      <WatermarkLogo src={logo} alt="dredyba-watermark" />

      <Header
        sx={{
          mt: {
            xs: "80px",
            md: 0,
          },
          height: { xs: "calc(100vh - 80px)", md: "100vh" },
        }}
      >
        <video
          ref={videoRef}
          autoPlay
          loop
          muted
          playsInline
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            minHeight: "100%",
            minWidth: "100%",
            zIndex: 1,
          }}
          src={matchDownMd ? headerVideoMobile : headerVideoDesktop}
        />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "#ffffff",
            position: "absolute",
            right: 0,
            zIndex: 2,
          }}
          onClick={() => {
            videoRef?.current?.play();
          }}
        >
          <Container maxWidth="md">
            <Typography variant="h3" component="h1">
              {t("header.welcome")}
            </Typography>

            <Typography variant="h3" component="h1">
              DREDY BA
            </Typography>

            <Typography textAlign="justify" variant="subtitle1" component="h2">
              {t("header.text")}
            </Typography>
          </Container>
        </Box>
      </Header>

      <Navbar />

      <StyledSection id="about" maxWidth="md">
        <Typography variant="h2">{t("sections.about")}</Typography>

        <Grid container spacing={4}>
          <Grid>
            <Typography textAlign="justify">{t("about.text1")}</Typography>

            <Box>
              <img
                src={aboutImg}
                alt="dredyba-studio"
                width="100%"
                loading="lazy"
              />
            </Box>

            <Typography textAlign="justify">{t("about.text2")}</Typography>
          </Grid>
        </Grid>
      </StyledSection>

      <StyledSectionImage style={{ backgroundImage: `url(${sectionImg1})` }} />

      <StyledSection id="services" maxWidth="md" sx={{ pb: 3 }}>
        <Typography variant="h2" gutterBottom>
          {t("sections.services")}
        </Typography>

        {services.map((service) => (
          <Grid
            container
            alignItems="center"
            key={service.key}
            marginBottom={2}
            spacing={1}
          >
            <Grid size={12}>
              <Typography variant="h3" textAlign="center">
                {t(`services.${service.key}.header`)}
              </Typography>
            </Grid>

            <Grid textAlign="center" size={{ xs: 12, sm: "auto" }}>
              <img
                src={service.img}
                alt={service.key}
                style={{
                  objectFit: "cover",
                  borderRadius: "50%",
                  width: "100%",
                  maxWidth: 250,
                }}
                loading="lazy"
              />
            </Grid>

            <Grid size={{ xs: 12, sm: "grow" }}>
              <Typography textAlign="justify">
                {t(`services.${service.key}.text`)}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </StyledSection>

      <StyledSectionImage style={{ backgroundImage: `url(${sectionImg2})` }} />

      <StyledSection id="portfolio" maxWidth="md" sx={{ pb: 3 }}>
        <Typography variant="h2" gutterBottom>
          {t("sections.portfolio")}
        </Typography>

        <ImageList sx={{}} cols={matchDownMd ? 1 : 2} gap={16}>
          {gallery.map((item) => (
            <ImageListItem key={item.img}>
              <img src={item.img} alt={item.title} loading="lazy" />
            </ImageListItem>
          ))}
        </ImageList>
      </StyledSection>

      <StyledSectionImage style={{ backgroundImage: `url(${sectionImg3})` }} />

      <StyledSection id="contact" maxWidth="md" sx={{ pb: 3 }}>
        <Typography variant="h2" gutterBottom>
          {t("sections.contact")}
        </Typography>

        <Grid container justifyContent="space-between">
          <Grid>
            <Typography textAlign="justify" gutterBottom>
              <Trans i18nKey="contact.text1">
                You can schedule an appointment by writing us on
                <Link href="https://www.facebook.com/dredyba/" target="_blank">
                  facebook
                </Link>
                or
                <Link
                  href="https://www.instagram.com/dredy.ba/"
                  target="_blank"
                >
                  instagram
                </Link>
                , alternatively
                <Link
                  href="https://api.whatsapp.com/send?phone=%2b421911192864&text=DREDY%20BA"
                  target="_blank"
                >
                  WhatsApp
                </Link>
                or SMS on phone number
                <Link href="tel:+421911192864">phone number</Link>
              </Trans>
            </Typography>

            <Typography textAlign="justify" gutterBottom>
              {t("contact.text2")}
            </Typography>
          </Grid>
          <Grid>
            <Typography fontStyle="normal" gutterBottom>
              {t("contact.openingHours")}
            </Typography>

            <Typography gutterBottom>
              Opavská 24
              <br />
              831 01
              <br />
              Bratislava
              <br />
              <Link href="tel:+421911192864">+421 911 192 864</Link>
            </Typography>
          </Grid>
        </Grid>
      </StyledSection>

      <StyledSectionImage
        style={{
          backgroundImage: `url(${buildingImg})`,
        }}
      />

      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10644.76158985248!2d17.0884396!3d48.1644116!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2b7a2efcae388527!2sDREDY%20BA%20-%20STUDIO!5e0!3m2!1sen!2ssk!4v1670001104656!5m2!1sen!2ssk"
        width="100%"
        style={{ border: 0, display: "block", height: "50vh" }}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />

      <StyledSectionImage
        style={{
          backgroundImage: `url(${sectionImg4})`,
        }}
      />

      <Footer />
    </Box>
  );
};
