import {
  AppBar,
  Container,
  Toolbar,
  Box,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  styled,
} from "@mui/material";
import logo from "../../assets/img/dredyba-logo-white.svg";
import MenuIcon from "@mui/icons-material/Menu";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LanguageIcon from "@mui/icons-material/Language";
import audioToots from "../../assets/audio/toots.mp3";

export const SECTIONS = ["about", "services", "portfolio", "contact"];

const StyledLogo = styled("img")`
  width: 70px;

  @media (prefers-reduced-motion: no-preference) {
    animation: dredyba-header-logo-spin infinite 60s linear;
    animation-play-state: paused;
  }

  @keyframes dredyba-header-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const logoRef = useRef<HTMLImageElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [langToChange, setLangToChange] = useState(
    i18n.language.includes("en") ? "sk" : "en"
  );

  useEffect(() => {
    if (isPlaying) {
      audioRef?.current?.play();
      if (logoRef.current) {
        logoRef.current.style.animationPlayState = "running";
      }
    } else {
      audioRef?.current?.pause();
      if (logoRef.current) {
        logoRef.current.style.animationPlayState = "paused";
      }
    }
  }, [isPlaying]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const changeLang = () => {
    i18n.changeLanguage(langToChange);
    const url = new URL(window.location.toString());

    if (langToChange === "en") {
      url.searchParams.set("lang", "en");
      window.history.pushState({}, "", url);
    } else {
      url.searchParams.delete("lang");
      window.history.pushState({}, "", url);
    }

    setLangToChange(i18n.language.includes("en") ? "sk" : "en");
  };

  const drawer = (
    <Box sx={{ textAlign: "left", width: "50vw", color: "#000000" }}>
      <List>
        {SECTIONS.map((item) => (
          <ListItem key={item}>
            <ListItemButton
              onClick={() => {
                document.getElementById(item)?.scrollIntoView();
                handleDrawerToggle();
              }}
            >
              <ListItemText primary={t(`sections.${item}`).toUpperCase()} />
            </ListItemButton>
          </ListItem>
        ))}

        <ListItem>
          <ListItemButton>
            <ListItemText
              primary={
                <>
                  <IconButton
                    sx={{ p: 0, mr: 1 }}
                    href="https://www.instagram.com/dredy.ba/"
                    target="_blank"
                    style={{ color: "#000000" }}
                  >
                    <InstagramIcon fontSize="inherit" />
                  </IconButton>

                  <IconButton
                    sx={{ p: 0 }}
                    href="https://www.facebook.com/dredyba/"
                    target="_blank"
                    style={{ color: "#000000" }}
                  >
                    <FacebookIcon fontSize="inherit" />
                  </IconButton>
                </>
              }
            />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={changeLang}>
            <ListItemText
              primary={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textTransform: "uppercase",
                  }}
                >
                  <LanguageIcon sx={{ mr: 1 }} />
                  {langToChange}
                </div>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar
      sx={{
        position: { xs: "fixed", md: "sticky" },
        bgcolor: "#000000",
      }}
    >
      <audio ref={audioRef} src={audioToots} autoPlay loop />

      <Container maxWidth="md">
        <Toolbar disableGutters>
          <IconButton
            sx={{ mr: "auto" }}
            onClick={() => {
              // document.getElementById("about")?.scrollIntoView();
              setIsPlaying(!isPlaying);
            }}
          >
            <StyledLogo ref={logoRef} src={logo} alt="dredyba-logo" />
          </IconButton>

          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {SECTIONS.map((page) => (
              <Button
                key={page}
                sx={{ color: "white" }}
                onClick={() => document.getElementById(page)?.scrollIntoView()}
              >
                {t(`sections.${page}`)}
              </Button>
            ))}

            <IconButton
              sx={{ color: "white" }}
              href="https://www.instagram.com/dredy.ba/"
              target="_blank"
            >
              <InstagramIcon />
            </IconButton>

            <IconButton
              sx={{ mr: 1, color: "white" }}
              href="https://www.facebook.com/dredyba/"
              target="_blank"
            >
              <FacebookIcon />
            </IconButton>

            <Button
              key={"language"}
              sx={{ color: "white" }}
              onClick={changeLang}
            >
              <LanguageIcon sx={{ mr: 1 }} />
              {langToChange}
            </Button>
          </Box>

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleDrawerToggle}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Box component="nav">
            <Drawer
              anchor="right"
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
