import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { i18nInit } from "./i18n";
import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

console.log(
  "%c BAD MOTHER FUCKER ",
  "background: #97593e; color: #1f1e2f; font-size: 30px; font-weight: bold;"
);

const urlParams = new URLSearchParams(window.location.search);
const lang = urlParams.get("lang");
if (lang === "en") {
  i18nInit(lang);
} else {
  i18nInit("sk");

  if (lang) {
    const url = new URL(window.location.toString());
    url.searchParams.delete("lang");
    window.history.pushState({}, "", url);
  }
}

let theme = createTheme({
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 20,
    // htmlFontSize: 20,
    fontFamily: "Merriweather",
  },
});

theme = responsiveFontSizes(theme);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
